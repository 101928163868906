import React, { useEffect, useRef, useState } from 'react'
import '../Navbar.css';
import navbarProfileRoutes, { mainPage, navbarPart, profilePage } from 'router/navbarProfile';
import NavbarItem from '../NavbarItem';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDeviceType } from 'components/hooks/useDeviceType';
import OAuthService from 'services/OAuthService';
import { setSidebarItem } from 'redux/slice/sidebarItemSlice';
import Strings from 'values/Strings';
const NavbarProfile = ({ pageName }) => {

    const [links, setLinks] = useState([])
    const listRef = useRef(null)
    const [itemRef, setItemRef] = useState(null)
    const selectorRef = useRef(null)
    const sidebarStatus = useSelector(state => state.sidebar.isActive)
    const device = useDeviceType()
    const dispatch = useDispatch()

    function animation() {
        if (itemRef?.current) {
            const activeItemNewAnim = itemRef?.current;
            const activeWidthNewAnimHeight = activeItemNewAnim.clientHeight;
            const activeWidthNewAnimWidth = activeItemNewAnim.clientWidth;
            const itemPosNewAnimTop = activeItemNewAnim.offsetTop;
            const itemPosNewAnimLeft = activeItemNewAnim.offsetLeft;
            selectorRef.current.style.top = `${itemPosNewAnimTop}px`
            selectorRef.current.style.left = `${itemPosNewAnimLeft}px`
            selectorRef.current.style.height = `${activeWidthNewAnimHeight}px`
            selectorRef.current.style.width = `${activeWidthNewAnimWidth}px`
        }
    }

    const sidebarItemsObject = [
        { page: mainPage, title: Strings.sidebarMain },
        { page: profilePage, title: Strings.sidebarProfile },
    ]

    useEffect(() => {
        animation();
        window.addEventListener('resize', function () {
            setTimeout(function () { animation(); }, 900);
        });
    }, [itemRef])

    useEffect(() => {
        sidebarItemsObject.forEach(element => {
            if (pageName.includes(element.page)) {
                dispatch(setSidebarItem(element.title))
            }
        });
    }, [])

    useEffect(() => {
        {
            device == "desktop" &&
                setTimeout(function () { animation(); }, 910)
        }
    }, [sidebarStatus])


    const location = useLocation()
    const userInfo = OAuthService.decryptedSync('userData')


    useEffect(() => {
        setLinks(navbarProfileRoutes.map((item, index) => {
            if (pageName.includes(item.pageName)) {
                if (item.part === navbarPart.other) {
                    return <NavbarItem setItemRef={setItemRef} key={index} Icon={item.icon} title={item.title} name={item.name} />
                }
                else if (item.part === navbarPart.jury && userInfo?.part?.name === navbarPart.jury) {
                    return <NavbarItem setItemRef={setItemRef} key={index} Icon={item.icon} title={item.title} name={item.name} />
                } else if (item.part === navbarPart.mentor && userInfo?.part?.name === navbarPart.mentor) {
                    return <NavbarItem setItemRef={setItemRef} key={index} Icon={item.icon} title={item.title} name={item.name} />
                }
            }
        }))
    }, [location.pathname, pageName]);

    return (
        <nav style={{ width: "100%" }} className="navbar navbar-expand-lg navbar-mainbg">
            <div
                className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto" ref={listRef}>

                    <div className="hori-selector" ref={selectorRef}>
                    </div>
                    {links}
                </ul>
            </div>
        </nav>
    )
}
export default NavbarProfile;