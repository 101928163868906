import { privateRoutNames, publickRoutNames } from "router";
import Strings from "values/Strings";

import { RiTeamLine } from 'react-icons/ri';
import { AiOutlineProfile } from 'react-icons/ai';
import { MdGrade } from 'react-icons/md';
import { BsBookmarkCheck } from 'react-icons/bs';
import { IoMdInformationCircleOutline } from "react-icons/io";
import { PiCertificateBold } from "react-icons/pi";
import { FaTasks } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
const gradePage = 'team-grade-page'
export const profilePage = 'profile'
export const mainPage = 'main'

export const navbarPart = {
    jury: 'jury',
    mentor: 'mentor',
    other: 'other'
}

const navbarProfileRoutes = [
    { name: `private/${privateRoutNames.TeamGradeMentorPage}`, pageName: gradePage, title: Strings.navbarProfilMentorPage, icon: RiTeamLine, part: navbarPart.mentor },
    { name: `private/${privateRoutNames.teamGradePageCheckPoint}`, pageName: gradePage, title: Strings.navbarProfilCheckPoint, icon: BsBookmarkCheck, part: navbarPart.mentor },
    { name: `private/${privateRoutNames.teamGradePageFinalMark}`, pageName: gradePage, title: Strings.navbarProfilFinalMark, icon: MdGrade, part: navbarPart.jury },
    { name: `private/${privateRoutNames.infoAboutCommand}`, pageName: gradePage, title: Strings.navbarInfoAboutCommand, icon: IoMdInformationCircleOutline, part: navbarPart.mentor },

    { name: `private/${privateRoutNames.personalData}`, pageName: profilePage, title: Strings.navbarProfileData, icon: CgProfile, part: navbarPart.other },
    { name: `private/${privateRoutNames.portfolio}`, pageName: profilePage, title: Strings.navbarProfileOccasion, icon: PiCertificateBold, part: navbarPart.other },
    { name: `private/${privateRoutNames.mentorCase}`, pageName: profilePage, title: Strings.navbarProfileCase, icon: FaTasks, part: navbarPart.other },

    { name: `${publickRoutNames.mainInfo}`, pageName: mainPage, title: Strings.navbarMain, icon: BsBookmarkCheck, part: navbarPart.other },
    { name: `${publickRoutNames.mainNews}`, pageName: mainPage, title: Strings.navbarMainNews, icon: MdGrade, part: navbarPart.other },
]

export default navbarProfileRoutes