import classes from "./AboutEvent.module.css"
import "@fontsource/roboto"
import { FcDocument } from 'react-icons/fc';
import { useDeviceType } from "components/hooks/useDeviceType";
import Button from "../buttons/button/Button";
import { useState } from "react";
import { useEffect } from "react";
import TitleMain from "../titleMain/TitleMain";
import Marquee from "react-fast-marquee";
import EDRO from "assets/images/AI/EDRO.png"
import School from "assets/images/AI/School.png"
import GazInfo from "assets/images/AI/gazInfo.png"
import GazEnerg from "assets/images/AI/gazEnerg.png"
import Intel from "assets/images/AI/intel.png"
import SurGu from "assets/images/AI/SurGu.png"
import Elastic from "assets/images/AI/Elastic.png"
import Art from "assets/images/companyOriginal/artl.png"
import Tech from "assets/images/companyOriginal/tech.png"
import Adm from "assets/images/companyOriginal/hmao.svg"
import Priority2030 from "assets/images/companyOriginal/priority2030.png"
import DSK1 from "assets/images/companyOriginal/DSK1.png"
import StudProd from "assets/images/companyOriginal/studprof.png"

const AboutEvent = ({ children, listAboutOccasion, participantPopUp, ...props }) => {

    const device = useDeviceType()
    const logo = [EDRO, Priority2030, DSK1, StudProd, School, GazInfo, SurGu, Intel, GazEnerg, Elastic, Art, Tech, Adm]

    return (

        <div className={classes.main__block__style__about}>
            <h3>{props.occasion}</h3>

            <div className={classes.first__block__about}>

                <div className={classes.up__text__style}>
                    <TitleMain text={"Информация о проведении"} />
                    {/* <span className={classes.text__style}>Информация о проведении мероприятия</span> */}
                    <div className={classes.date__time__place__style}>
                        <ul>
                            <li>
                                <span>Когда?  -  {props.date}</span>
                            </li>

                            <li>
                                <span>Во сколько?  -  {props.time}</span>
                            </li>

                            <li>
                                <span>Где?  -  {props.place}</span>
                            </li>
                        </ul>
                    </div>

                </div>

                <div className={classes.up__text__style}>
                    <TitleMain text={"Описание мероприятия"} />
                    {/* <span className={classes.text__style}>Описание мероприятия</span> */}
                    <div className={classes.description__style}>
                        <span>{props.description}</span>
                    </div>
                </div>
            </div>

            <div className={classes.second__block__about}>
                {listAboutOccasion[0]?.name &&
                    <div className={classes.up__text__style}>
                        <TitleMain text={"Список участников"} />
                        {/* <span className={classes.text__style}>Список участников</span> */}
                        <div className={`${classes.list__participants__style} ${classes.border__for__list}`}>
                            <ul>
                                {listAboutOccasion.map((item, i) => <li key={i}>{item.name}</li>)}
                            </ul>
                        </div>
                    </div>
                }

                <div className={classes.up__text__style}>
                    <TitleMain text={"События по времени"} />
                    {/* <span className={classes.text__style}>События по времени</span> */}
                    <div className={`${classes.time__management__style} ${classes.border__for__list}`}>
                        <span style={{ whiteSpace: 'pre-wrap' }}>{props.time_managment}</span>
                    </div>
                </div>
            </div>

            <div className={classes.about__logo}>
                <Marquee speed={100} gradientWidth={"15vw"} gradientColor={"#FFF"} gradient={true}>
                    {logo.map((any, i) => <img key={i} src={any} alt="" className={classes.about__logo__img} />)}
                </Marquee>
            </div>


            <div className={classes.button__style}>
                <Button onClick={participantPopUp}>Участвовать</Button>
            </div>


            <div className={classes.third__block__about}>
                <TitleMain text={"Документы мероприятия"} />
                {/* <span className={classes.docum__text__style}>Документы мероприятия</span> */}
                <div className={classes.doc__main__block}>
                    <div className={classes.document__style}>
                        <a href={props.minors}>
                            <FcDocument className={classes.icon__style} />
                            <span>Допуск для несовершен.</span>
                        </a>
                    </div>

                    <div className={classes.document__style}>
                        <a href={props.condition}>
                            <FcDocument className={classes.icon__style} />
                            <span>Положение мероприятия</span>
                        </a>

                    </div>

                    <div className={classes.document__style}>
                        <a href={props.criteria}>
                            <FcDocument className={classes.icon__style} />
                            <span>Критерии оценивания мероприятия</span>
                        </a>
                    </div>
                </div>

                {props.location &&
                    <div className={classes.block__map}>
                        <TitleMain text={"Место проведения мероприятия"} />
                        {/* <span>Место проведения мероприятия на карте</span> */}
                        <iframe src={props.location} frameBorder="0" className={classes.map__style}></iframe>
                    </div>
                }
            </div>

        </div >

    )
}

export default AboutEvent