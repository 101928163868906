import { useRef, useState } from 'react';
import classes from './DragEndDropFile.module.css'
import DragAndDrop from 'components/UI/dragAndDrop/DragAndDrop';
import DragAndDropPlaceholder from 'components/UI/dragAndDrop/DragAndDropPlaceholder';
import { AiOutlineClose } from 'react-icons/ai';


function DragEndDropFile({ file, setFile }) {
    const [dragEndDropText, setDragEndDropText] = useState("")
    const dragZoneRef = useRef()

    const removeFile = () => setFile(null)

    return (
        <div className={classes.wrapper}>
            {file
                ? <div className={classes.presentation__wrapper}>
                    <div className={classes.presentation__container}>
                        <span>Файл загружен</span> <AiOutlineClose onClick={removeFile} color="rgb(210, 12, 12)" />
                    </div>
                </div>
                : <DragAndDrop justifyContent="left" imagePreview={setFile} borderStyle={'none'} dragZoneRef={dragZoneRef}
                    setDragZoneText={setDragEndDropText} textDragZone="Загрузить список участников">
                    <DragAndDropPlaceholder height={'15vw'} width={'60vw'} right={'0vw'} imagePreview={setFile} dragZoneRef={dragZoneRef}>{dragEndDropText}</DragAndDropPlaceholder>
                </DragAndDrop>
            }
        </div>
    );
}

export default DragEndDropFile;