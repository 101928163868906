import Strings from "values/Strings";
// -------------- ICONST FOR SIDEBAR ----------------
import { AiOutlineHome } from 'react-icons/ai';
import { MdOutlineEmojiEvents } from 'react-icons/md';
import { BiBriefcase } from 'react-icons/bi';
import { AiOutlineContacts } from 'react-icons/ai';
import { RiProfileLine } from 'react-icons/ri';
import { BiExit } from 'react-icons/bi';
// import { RxExit } from 'react-icons/rx';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { RiTeamLine } from 'react-icons/ri';
import { PiMicrosoftTeamsLogo } from 'react-icons/pi';
import { navBareRoute, privateRoutNames } from "router";

// const rights = {
//     admin: false,
//     private: false,
//     command: false,
//     jury: false,
// }


const sidebarRoutes = [
    { name: `${navBareRoute.main}/info`, title: Strings.sidebarMain, icon: AiOutlineHome, rights: { private: false } },

    { name: 'occasion', title: Strings.sidebarOccasion, icon: MdOutlineEmojiEvents, rights: { private: false } },
    { name: 'case', title: Strings.sidebarCase, icon: BiBriefcase, rights: { private: false } },


    { name: `private/${navBareRoute.profile}/personal-data`, title: Strings.sidebarProfile, icon: MdOutlineAdminPanelSettings, rights: { private: true } },
    // { name: 'private/create-case', title: Strings.createCase, icon: AiOutlineContacts, rights: { private: true } },

    { name: 'private/team-room', title: Strings.teamRoom, icon: PiMicrosoftTeamsLogo, rights: { command: true } },

    (false ? { name: `private/${navBareRoute.tgp}/check-point`, title: Strings.sidebarGrade, icon: RiTeamLine, rights: { jury: true } }
        : { name: `private/${privateRoutNames.teamGradePageFinalMark}`, title: Strings.sidebarGrade, icon: RiTeamLine, rights: { jury: true } }),

    { name: 'support', title: Strings.sidebarSupport, icon: AiOutlineContacts, rights: { private: false } },
    { name: `${navBareRoute.admin}/administration`, title: Strings.sidebarAdminPanel, isActiveAdminPanel: true, icon: RiProfileLine, rights: { admin: true } },
]


const sidebarAuthRoutes = [
    { name: 'enter', title: Strings.sidebarEnter, icon: BiExit },
    { name: 'logout', title: Strings.sidebarExit, icon: BiExit, newPageTitle: Strings.sidebarMain },
]

export { sidebarRoutes, sidebarAuthRoutes }










