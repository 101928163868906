import classes from "./Сertificate.module.css"
import "@fontsource/roboto"
import InputBlock from "../inputBlock/InputBlock"
import { useEffect, useState } from "react"
import { useDeviceType } from "components/hooks/useDeviceType"
import 'rc-tooltip/assets/bootstrap_white.css';
import Loader from "../loader/Loader"
import OccasionService from "services/OccasionService"
import Button from "components/UI/buttons/button/Button";
import TextLocation from "./textLocation/TextLocation"
import ColorPicker from "./colorPicker/ColorPicker"
import TextCoordinates from "./textCoordinates/TextCoordinates"
import Answer from "./ answer/Answer"
import DragEndDropFile from "./dragEndDropFile/DragEndDropFile"

const Сertificate = ({ className = '', setting, setSetting, children, occasions, occasionList, currentOccasion, setCurrentOccasion,
    currentCommand, setCurrentCommand, command, commandList,
    coordinates, setCoordinates, currentCoordinates, setCurrentCoordinates,
    imagePreviewRef, originalImageSize, copySettings, pastSettings,
    exampleData, setExampleData, setInputCoordinate,
    setFioTextAlign, setTeamNameTextAlign, fonts, handleMouseMove, fetchingCommand,
    setQRCodeIsUse, setTeamNameIsUse,
    excel, setExcel,
    nameForAllCertificateGenerate, ...props }) => {

    const [selectItem, setSelectItem] = useState(null)

    function focusCoordinatesChange(choose) {
        setSelectItem(choose)
    }

    function changeOcassion(data, index) {
        setCurrentOccasion(occasions[index])
        fetchingCommand(occasions[index])
    }

    function choiceFontFIO(data, index) {
        setSetting(old => ({ ...old, fontFIO: fonts[index] }))
    }

    function choiceFontTeamName(data, index) {
        setSetting(old => ({ ...old, fontCommandName: fonts[index] }))
    }

    function changeCommand(data, index) {
        setCurrentCommand(command[index])
    }

    const device = useDeviceType()
    const activateQRCode = () => setInputCoordinate('qrCode')
    const activateTeamName = () => setInputCoordinate('teamName')
    const activateFio = () => setInputCoordinate('fio')

    const [qrCodePicker, setQrCodePicker] = useState(false)
    const [commandNamePicker, setCommandNamePicker] = useState(false)
    const [fioPicker, setFioPicker] = useState(false)

    return (
        <Loader>
            <div className={`${classes.main__block__style} ${className}`}>
                {!excel && (occasions?.length >= 1) && ((device === "phone")
                    ? <div className={classes.occasion__style}>
                        <InputBlock fullWidth={true} readOnly={true} indexStatus={true} placeholder={"Мероприятие"} onListStatus={true} list={occasionList} value={OccasionService.getTextInputOccasion(currentOccasion)} setValue={changeOcassion} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </div >
                    :
                    <div className={classes.occasion__style}>
                        <InputBlock fullWidth={true} placeholder={"Мероприятие"} readOnly={true} indexStatus={true} onListStatus={true} list={occasionList} value={OccasionService.getTextInputOccasion(currentOccasion)} setValue={changeOcassion} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </div>
                )}
                {!(Object.keys(currentOccasion).length > 0) &&
                    <div className={classes.occasion__style}>
                        <DragEndDropFile file={excel} setFile={setExcel} />
                    </div>
                }
                {((Object.keys(currentOccasion).length > 0 && !excel)) &&
                    (device === "phone"
                        ? <div className={classes.style__margin}>
                            <InputBlock fullWidth={true} readOnly={true} indexStatus={true} placeholder={"Команда для печати дипломов / сертификатов"} onListStatus={true} list={commandList} value={currentCommand?.name ? currentCommand.name : ''} setValue={changeCommand}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                        </div>
                        : <div className={classes.block__style}>
                            <InputBlock fullWidth={true} placeholder={"Команда"} readOnly={true} indexStatus={true} onListStatus={true} list={commandList} value={currentCommand?.name ? currentCommand.name : ''} setValue={changeCommand} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                        </div>)
                }

                {(Object.keys(currentOccasion).length > 0 || excel) &&
                    (device === "phone"
                        ? <div className={classes.style__margin}>
                            <InputBlock fullWidth={true} value={setting.countFIO} setValue={(countFIO) => setSetting(old => ({ ...old, countFIO }))} placeholder={"Кол-во людей в одной строке"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>
                        : <div className={classes.block__style}>
                            <InputBlock fullWidth={true} placeholder={"Кол-во ФИО в строке"} value={setting.countFIO} setValue={(countFIO) => setSetting(old => ({ ...old, countFIO }))} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>)
                }
                {/* --------------------------------------------Цвет HEXA ФИО------------------------------------------------------------- */}
                {(Object.keys(currentOccasion).length > 0 || excel) &&
                    (device === "phone"
                        ? <div className={classes.style__margin}>
                            <InputBlock fullWidth={true} value={setting.colorFIO} setValue={(colorFIO) => setSetting(old => ({ ...old, colorFIO }))} placeholder={"Кол-во людей в одной строке"}
                                onFocus={() => setFioPicker(true)}
                                onBlur={() => setFioPicker(false)}
                                colorInvert={true}
                                backgroundColor={setting.colorFIO}
                                onListStatus={false}></InputBlock>
                        </div>
                        : <div className={classes.block__style}>
                            <InputBlock fullWidth={true} placeholder={"Цвет HEXA ФИО"} value={setting.colorFIO} setValue={(colorFIO) => setSetting(old => ({ ...old, colorFIO }))}
                                onFocus={() => setFioPicker(true)}
                                onBlur={() => setFioPicker(false)}
                                colorInvert={true}
                                backgroundColor={setting.colorFIO}
                                onListStatus={false}></InputBlock>
                        </div>)
                }
                {
                    (Object.keys(currentOccasion).length > 0 || excel) && fioPicker &&
                    <ColorPicker setSetting={setSetting} setting={setting} settingsKey={'colorFIO'} />
                }
                {/* --------------------------------------------Цвет HEXA Имя команды------------------------------------------------------------- */}
                {(setting?.teamNameIsUse && (Object.keys(currentOccasion).length > 0 || excel) && ((currentCommand.name) !== '')) &&
                    (device === "phone"
                        ? <div className={classes.style__margin}>
                            <InputBlock fullWidth={true} value={setting.colorCommandName} setValue={(colorCommandName) => setSetting(old => ({ ...old, colorCommandName }))}
                                placeholder={"Цвет HEXA имя команды"} onListStatus={false}
                                onFocus={() => setCommandNamePicker(true)}
                                onBlur={() => setCommandNamePicker(false)}
                                backgroundColor={setting.colorCommandName}
                                colorInvert={true}></InputBlock>
                        </div>
                        : <div className={classes.block__style}>
                            <InputBlock fullWidth={true} placeholder={"Цвет HEXA имя команды"} value={setting.colorCommandName} setValue={(colorCommandName) => setSetting(old => ({ ...old, colorCommandName }))}
                                onFocus={() => setCommandNamePicker(true)}
                                onBlur={() => setCommandNamePicker(false)}
                                colorInvert={true}
                                backgroundColor={setting.colorCommandName} onListStatus={false}></InputBlock>
                        </div>)
                }
                {
                    (Object.keys(currentOccasion).length > 0 || excel) && commandNamePicker &&
                    <ColorPicker setSetting={setSetting} setting={setting} settingsKey={'colorCommandName'} />
                }
                {/* --------------------------------------------Цвет HEXA qrcode------------------------------------------------------------- */}
                {(Object.keys(currentOccasion).length > 0 || excel) &&
                    (device === "phone"
                        ? <div className={classes.style__margin}>
                            <InputBlock fullWidth={true} value={setting.colorQRCode} setValue={(colorQRCode) => setSetting(old => ({ ...old, colorQRCode }))}
                                placeholder={"Цвет HEXA qr code"} onListStatus={false}
                                onFocus={() => setQrCodePicker(true)}
                                onBlur={() => setQrCodePicker(false)}
                                backgroundColor={setting.colorQRCode}
                                colorInvert={true}></InputBlock>
                        </div>
                        : <div className={classes.block__style}>
                            <InputBlock fullWidth={true} placeholder={"Цвет HEXA qr code"} value={setting.colorQRCode} setValue={(colorQRCode) => setSetting(old => ({ ...old, colorQRCode }))}
                                onFocus={() => setQrCodePicker(true)}
                                onBlur={() => setQrCodePicker(false)}
                                colorInvert={true}
                                backgroundColor={setting.colorQRCode} onListStatus={false}></InputBlock>
                        </div>)
                }
                {
                    (Object.keys(currentOccasion).length > 0 || excel) && qrCodePicker &&
                    <ColorPicker setSetting={setSetting} setting={setting} settingsKey={'colorQRCode'} />
                }
                {/* ---------------------------------------------------------------------------------------------------------------------------- */}
                {(Object.keys(currentOccasion).length > 0 || excel) &&
                    (device === "phone"
                        ? <div className={classes.style__margin}>
                            <InputBlock fullWidth={true} value={setting.kegelFIO} setValue={(kegelFIO) => setSetting(old => ({ ...old, kegelFIO }))} placeholder={"Кол-во людей в одной строке"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>
                        : <div className={classes.block__style}>
                            <InputBlock fullWidth={true} placeholder={"Кегель ФИО"} value={setting.kegelFIO} setValue={(kegelFIO) => setSetting(old => ({ ...old, kegelFIO }))} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>)
                }
                {((Object.keys(currentOccasion).length > 0 || excel) && setting?.teamNameIsUse) &&
                    (device === "phone"
                        ? <div className={classes.style__margin}>
                            <InputBlock fullWidth={true} value={setting.kegelCommandName} setValue={(kegelCommandName) => setSetting(old => ({ ...old, kegelCommandName }))} placeholder={"Кол-во людей в одной строке"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>
                        : <div className={classes.block__style}>
                            <InputBlock fullWidth={true} placeholder={"Кегель команды"} value={setting.kegelCommandName} setValue={(kegelCommandName) => setSetting(old => ({ ...old, kegelCommandName }))} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>)
                }
                {(Object.keys(currentOccasion).length > 0 || excel) &&
                    (device === "phone"
                        ? <div className={classes.style__margin}>
                            <InputBlock fullWidth={true} value={setting.widthFIO} setValue={(widthFIO) => setSetting(old => ({ ...old, widthFIO }))} placeholder={"Кол-во людей в одной строке"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>
                        : <div className={classes.block__style}>
                            <InputBlock fullWidth={true} placeholder={"Ширина блока ФИО"} value={setting.widthFIO} setValue={(widthFIO) => setSetting(old => ({ ...old, widthFIO }))} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>)
                }
                {((Object.keys(currentOccasion).length > 0 || excel) && setting?.teamNameIsUse) &&
                    (device === "phone"
                        ? <div className={classes.style__margin}>
                            <InputBlock fullWidth={true} value={setting.widthCommandName} setValue={(widthCommandName) => setSetting(old => ({ ...old, widthCommandName }))} placeholder={"Кол-во людей в одной строке"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>
                        : <div className={classes.block__style}>
                            <InputBlock fullWidth={true} placeholder={"Ширина блока Имя команды"} value={setting.widthCommandName} setValue={(widthCommandName) => setSetting(old => ({ ...old, widthCommandName }))} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>)
                }
                {((Object.keys(currentOccasion).length > 0 || excel) && setting?.qrCodeIsUse) &&
                    (device === "phone"
                        ? <div className={classes.style__margin}>
                            <InputBlock fullWidth={true} value={setting.sizeQRCode} setValue={(sizeQRCode) => setSetting(old => ({ ...old, sizeQRCode }))} placeholder={"Размер QR-кода, который будет отображаться на сертификате"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>
                        : <div className={classes.block__style}>
                            <InputBlock fullWidth={true} placeholder={"Размер QR кода"} value={setting.sizeQRCode} setValue={(sizeQRCode) => setSetting(old => ({ ...old, sizeQRCode }))} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>)
                }
                {(Object.keys(currentOccasion).length > 0 || excel) &&
                    (device === "phone"
                        ? <div className={classes.style__margin}>
                            <InputBlock fullWidth={true} readOnly={true} indexStatus={true} placeholder={"Шрифт ФИО"} onListStatus={true} list={commandList} value={setting?.fontFIO ? setting?.fontFIO : ''} setValue={choiceFontFIO}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                        </div>
                        : <div className={classes.block__style}>
                            <InputBlock fullWidth={true} placeholder={"Шрифт ФИО"} readOnly={true} indexStatus={true} onListStatus={true} list={fonts} value={setting?.fontFIO ? setting?.fontFIO : ''} setValue={choiceFontFIO} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                        </div>)
                }
                {((Object.keys(currentOccasion).length > 0 || excel) && setting?.teamNameIsUse) &&
                    (device === "phone"
                        ? <div className={classes.style__margin}>
                            <InputBlock fullWidth={true} readOnly={true} indexStatus={true} placeholder={"Шрифт Команды"} onListStatus={true} list={commandList} value={setting?.fontCommandName ? setting?.fontCommandName : ''} setValue={choiceFontTeamName}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                        </div>
                        : <div className={classes.block__style}>
                            <InputBlock fullWidth={true} placeholder={"Шрифт команды"} readOnly={true} indexStatus={true} onListStatus={true} list={fonts} value={setting?.fontCommandName ? setting?.fontCommandName : ''} setValue={choiceFontTeamName} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                        </div>)
                }

                {(Object.keys(currentOccasion).length > 0 || excel) &&
                    (device === "phone"
                        ? <div className={classes.style__margin}>
                            <InputBlock fullWidth={true} value={setting.lineHeight} setValue={(lineHeight) => setSetting(old => ({ ...old, lineHeight }))} placeholder={"Кол-во людей в одной строке"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>
                        : <div className={classes.block__style}>
                            <InputBlock fullWidth={true} placeholder={"Рас. между строками"} value={setting.lineHeight} setValue={(lineHeight) => setSetting(old => ({ ...old, lineHeight }))} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>)
                }
                {(Object.keys(currentOccasion).length > 0 || excel) &&
                    <>
                        <div className={classes.example__wrapper}>
                            <div className={classes.coordinates}>
                                <Answer defaultValue={setting?.qrCodeIsUse} setState={setQRCodeIsUse} title={'Нужен QR код?'} />
                            </div>
                            <div className={classes.coordinates}>
                                <Answer defaultValue={setting?.teamNameIsUse} setState={setTeamNameIsUse} title={'Название команды нужен?'} />
                            </div>
                        </div>
                        <div className={classes.example__wrapper}>
                            <div className={classes.coordinates}>
                                <TextLocation setLocation={setFioTextAlign} title={'Расположение ФИО'} />
                            </div>
                        </div>
                        {(setting?.teamNameIsUse) &&
                            <div className={classes.example__wrapper}>
                                <div className={classes.coordinates}>
                                    <TextLocation setLocation={setTeamNameTextAlign} title={'Расположение название команды'} />
                                </div>
                            </div>}
                        <div className={classes.coordinates__container}>
                            <div className={classes.coordinates__wrapper}>
                                <TextCoordinates onF={() => focusCoordinatesChange(0)} focus={selectItem === 0 ? 1 : 0} title={'ФИО участников'} objName={'fio'} onChange={handleMouseMove} activate={activateFio} currentCoordinates={currentCoordinates} />
                            </div>
                            {(setting?.teamNameIsUse) &&
                                <div className={classes.coordinates__wrapper}>
                                    <TextCoordinates onF={() => focusCoordinatesChange(1)} focus={selectItem === 1 ? 1 : 0} title={'Название команды'} objName={'teamName'} onChange={handleMouseMove} activate={activateTeamName} currentCoordinates={currentCoordinates} />
                                </div>}
                            {setting?.qrCodeIsUse && <div className={classes.coordinates__wrapper}>
                                <TextCoordinates onF={() => focusCoordinatesChange(2)} focus={selectItem === 2 ? 1 : 0} title={'QRCode'} objName={'qrCode'} onChange={handleMouseMove} activate={activateQRCode} currentCoordinates={currentCoordinates} />
                            </div>}
                        </div>
                        {(setting?.teamNameIsUse) && <div className={classes.example__wrapper}>
                            <div className={classes.coordinates}>
                                <InputBlock fullWidth={true} placeholder={"Название команды для примера"} value={exampleData.teamName} setValue={value => setExampleData(old => ({ ...old, teamName: value }))} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </div>
                        </div>}
                        <div className={classes.example__wrapper}>
                            <div className={classes.coordinates}>
                                <InputBlock fullWidth={true} placeholder={"ФИО для примера"} value={exampleData.fio} setValue={value => setExampleData(old => ({ ...old, fio: value }))} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </div>
                        </div>
                        <Button onClick={copySettings} style={{ width: '15vw', marginBottom: '1vw' }}>Скопировать настройки</Button>
                        <Button onClick={pastSettings} style={{ width: '15vw', marginBottom: '1vw' }}>Вставить настройки</Button>
                    </>
                }
            </div>
        </Loader>
    )
}

export default Сertificate